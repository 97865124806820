<template>
    <div class="main-wrapper angle yellow-angle">
        <div class="content-event">

            <div>
                <p class="occhiello">
                    Ongoing event
                </p>
                <div class="title">{{ this.eventdata.attributes.title }}</div>
                <!--<div class="description" v-if="eventdata.attributes" v-html="this.eventdata.attributes.field_event_description.processed"></div>-->
                <div class="description" v-if="eventdata.attributes.field_event_description" v-html="eventdata.attributes.field_event_description.summary">
                </div>
            </div>

            <div>
                <div class="dates">
                    <div class="day">{{ eventStartDate.getDate() }}</div>
                    <div class="month-year">{{ monthStart  + ' ' + eventStartDate.getFullYear() }}</div>
                </div>

                <div class="clock">
                    <!--<div class="hours">
                        <p v-html="this.startHour"></p>
                        {{ this.sameDay? '-' : '' }}
                        <p v-if="this.sameDay" v-html="this.endHour"></p>
                    </div>-->
                    <div class="location">
                        <p>{{ this.eventdata.attributes.field_event_location }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="image">
            <img :src="photoSource(this.eventdata)" alt="">
        </div>
    </div>
</template>

<script>
    import {
        convertMonth
    } from '../libs/utils'

    export default {
        name: 'main-event-tile',
        props: {
            eventdata: Object
        },
        methods: {
            photoSource(ev) {
                if (ev) {
                    if (ev.field_event_image) {
                        return process.env.VUE_APP_ENDPOINT + ev.field_event_image.attributes.uri.url
                    } else {
                        return '/collection-newspapers.jpg'
                    }
                } else {
                    return '/collection-newspapers.jpg'
                }
            },
        },
        computed: {
            sameDay() {
                return (this.eventStartDate.getDate() == this.eventEndDate.getDate()
                    && this.eventStartDate.getMonth() == this.eventEndDate.getMonth()
                    && this.eventStartDate.getFullYear() == this.eventEndDate.getFullYear())
            },
            eventStartDate() {
                return new Date(Date.parse(this.eventdata.attributes.field_event_start_date))
            },
            eventEndDate() {
                return new Date(Date.parse(this.eventdata.attributes.field_event_end_date))
            },
            monthStart() {
                return convertMonth(this.eventStartDate.getMonth())
            },
            monthEnd() {
                return convertMonth(this.eventEndDate.getMonth())
            },
            startHour() {
                let d = new Date(Date.parse(this.eventdata.attributes.field_event_start_date))
                let h = d.getHours()
                let m = d.getMinutes() == 0 ? '00' : d.getMinutes().toString()
                if (h > 12) h = (h - 12).toString() + ':' + m + ' <span>pm</span>'
                else h = h.toString() + ':' + m + ' <span>am</span>'
                return h
            },
            endHour() {
                let d = new Date(Date.parse(this.eventdata.attributes.field_event_end_date))
                let h = d.getHours()
                let m = d.getMinutes() == 0 ? '00' : d.getMinutes().toString()
                if (h > 12) h = (h - 12).toString() + ':' + m + ' <span>pm</span>'
                else h = h.toString() + ':' + m + ' <span>am</span>'
                return h
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../styles/colors.scss";

    .main-wrapper {
        position: relative;
        background: #fff;
        display: flex; display: -webkit-flex;
        flex-direction: row;
        overflow: hidden;
        max-height: 730px;
        border-radius: 0px 0px 30px 0px;
        align-items: stretch;

        .content-event {
            padding: 60px 40px 60px 60px;
            width: 50%;
            display: flex; display: -webkit-flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            background-image: url("../assets/filigrana-logo-event.png");
            background-size: 50%;
            background-repeat: no-repeat;
            background-position: bottom right;

            &:before {
                content: "";
                width: 60px;
                height: 50%;
                position: absolute;
                top: 0;
                right: -60px;
                background: white;
                z-index: 99;
            }

            .occhiello {
                font-size: 1.25rem;
                color: $mainColor;
                text-transform: uppercase;
                position: relative;
                left: 40px;

                &:before {
                    content: "";
                    width: 20px;
                    height: 21px;
                    position: absolute;
                    top: 6px;
                    left: -35px;
                    background: url("../assets/icon-calendar.svg");
                    @media (max-width: 992px) {
                        left: -40px;
                    }
                }
            }

            .title {
                font-size: 3.25rem;
                margin-bottom: 20px;
            }

            .description,
            .description ::v-deep p {
                font-size: 1.125rem;
                margin-bottom: 40px;
                line-height: 1.9rem;
            }

            .day {
                font-size: 6.25rem;
                color: $mainColor;
                font-weight: 300;
            }

            .month-year {
                font-size: 1.375rem;
                color: #999;
                text-transform: uppercase;
                margin-bottom: 10px;
            }

            

            .hours,
            .location {
                margin-top: 0;
                margin-bottom: 0;
                padding-top: 0;
                padding-bottom: 0;

                margin-bottom: 10px;
                margin-top: 10px;
                background: $mainColor;
                padding: .5em 1em;
                width: fit-content;

                p {
                    font-weight: 700;
                    font-size: 2.125rem;
                    display: inline-block;
                    margin: 0;
                }
            }

            .dates {
                display: flex; display: -webkit-flex;
                flex-direction: column;
            }
        }

        .image {
            width: 50%;
            display: flex; display: -webkit-flex;
            justify-content: center;
            overflow: hidden;
            position: relative;
            max-height: unset;

            &::after {
                content: "";
                opacity: .5;
                background-image: url("../assets/pattern-overlay-person.png");
                width: 100%;
                height: calc(100% - 4px);
                z-index: 0;
                top: 0;
                right: 0px;
                display: block;
                position: absolute;
            }

            img {
                object-fit: cover;
                -o-object-fit: cover;
                display: block;
            }
        }
    }
    
    @media (max-width:1400px) {
        .main-wrapper .content-event {
            padding: 30px 30px 30px 40px;
            .occhiello{
                font-size: 1rem;
                left: 36px;
                &:before{
                    transform: scale(0.8);
                }
            }
            .title {
                margin-bottom: 10px;
                font-size: 3rem;
            }
            .description {
                font-size: 1rem;
                margin-bottom: 20px;
            }
            .day {
                font-size: 5.25rem;
            }

             .hours,
             .location {
                p{
                    font-size:1.625rem;
                }
            }
        }
    }

    @media (max-width: 992px) {
        .main-wrapper {
            flex-direction: column-reverse;
            max-height: unset;
            margin-top: 2rem;

            .content-event {
                padding: 30px 20px;
                width: 100%;

                .occhiello {
                    font-size: 1rem;
                    left: 40px;
                }

                .title {
                    font-size: 2.5rem;
                }

                .description {
                    margin-bottom: 20px;
                    font-size: 1rem;
                }

                .clock {
                    flex-direction: row;
                    display: flex; display: -webkit-flex;

                    //.location { margin-left: 20px; }
                }

                .day {
                    font-size: 3.75rem;
                }

                .month-year {
                    margin: 0;
                    font-size: 1.125rem;
                }

                >div {
                    padding: 10px;
                }

                .hours,
                .location {
                    p {
                        font-size: 1.375rem;
                    }
                }
            }

            .image {
                width: 100%;
                max-height: 200px;
            }
        }
    }

    @media (max-width: 768px) {
        .main-wrapper {
            border-radius: 0px 0px 20px 0px;
            margin-top: 0px;

            .content-event {
                >div {
                    padding: 10px 0px;
                }
            }
        }
    }

    @media (max-width:576px) {
        .main-wrapper .content-event {
            .clock {
                display: block;

                .location {
                    margin-left: 0px;
                }
            }

        }
    }

</style>